$primary: #2d5986 !default;
$secondary: #4e8ecf !default;
$white: #ffffff !default;
$gray-100: #f8f9fa !default;
$gray-200: #f3eeec !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #908a88 !default;
$gray-700: #495057 !default;
$gray-800: #332d2c !default;
$gray-900: #291d21 !default;
$black: #0d0d0d !default;

$theme-colors: (
	"tertiary": #4e8ecf,
	"new-color": #cc00ff,
	"new-gradient": #ff0055,
	"custom-brown": #8d7871,
) !default;

[class*="nav-blur"] {
	backdrop-filter: blur(50px);
	--webkit-backdrop-filter: blur(50px);
}

[class*="quicklinks-gradient"] {
	background: linear-gradient(90deg, #2d5986 0%, #4e8ecf 100%);
}

[class*="header-gradient"] {
	backdrop-filter: blur(80px);
	background-image: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.8) 0%,
		rgba(247, 242, 240, 0.8) 100%
	);
}

[class*="addr-gradient"] {
	background-image: linear-gradient(90deg, #e3d6d2 0%, #e3ddd8 100%);
}

$body-bg: $white !default;
$body-color: $gray-900 !default;

$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

$border-width: 1px !default;
$border-color: rgba($gray-900, 0.15) !default;

$border-radius: 14px !default;
$border-radius-lg: 22px !default;
$border-radius-sm: 8px !default;

@import url("https://use.typekit.net/fyw5hav.css");

$font-family-base: "agenda", sans-serif !default;
$headings-font-family: "agenda", sans-serif !default;
$display-font-family: "lust-display", serif !default;
$blockquote-font-family: "agenda", sans-serif !default;
$navbar-font-family: "agenda", sans-serif !default;
$navbar-brand-font-family: "agenda", sans-serif !default;
$btn-font-family: "agenda", sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$font-weight-base: 400 !default;
$line-height-base: 1.5 !default;

$h1-font-size: 50px !default;
$h2-font-size: 42px !default;
$h3-font-size: 36px !default;
$h4-font-size: 28px !default;
$h5-font-size: 26px !default;
$h6-font-size: 22px !default;

$h1-font-weight: 400 !default;
$h2-font-weight: 400 !default;
$h3-font-weight: 400 !default;
$h4-font-weight: 400 !default;
$h5-font-weight: 400 !default;
$h6-font-weight: 400 !default;

$headings-margin-bottom: 1rem !default;
$headings-line-height: 1.2 !default;
$headings-letter-spacing: 0 !default;
$headings-color: inherit !default;
$headings-text-transform: initial !default;
$headings-font-style: normal !default;

$display1-size: 100px !default;
$display2-size: 80px !default;
$display3-size: 45px !default;
$display4-size: 40px !default;

$display1-weight: 400 !default;
$display2-weight: 400 !default;
$display3-weight: 400 !default;
$display4-weight: 400 !default;

$display-line-height: 1.1 !default;
$display-text-transform: initial !default;
$display-font-style: normal !default;
$display-letter-spacing: -0.02em;
$display-color: inherit !default;

$lead-font-size: 20px !default;
$lead-font-weight: 300 !default;
$lead-line-height: 1.5 !default;

$small-font-size: 14px !default;
$extra-small-font-size: 11px !default;
$small-line-height: 1.4 !default;
$small-2-font-size: 0.9rem !default;

$blockquote-font-size: 18px !default;
$blockquote-font-style: normal !default;
$blockquote-font-weight: 400 !default;
$blockquote-letter-spacing: 0 !default;
$blockquote-line-height: 1.6 !default;

$hr-border-color: $border-color !default;
$hr-border-width: 1px !default;

$backdrop-color: rgba($black, 0.75);

$label-font-size: 0.8rem;
$label-line-height: 1.2em;
$label-letter-spacing: 0;
$label-font-weight: bold;
$label-text-transform: initial;

$input-placeholder-color: rgba(56, 48, 45, 0.5);
$input-border-color: $border-color;
$input-padding-y: 0.8rem;
$input-padding-x: 1rem;
$input-box-shadow: none;
$input-border-width: 1px;
$input-border-radius: 8px !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

/*
 * 	Navigation Top Level Items
 */

$nav-font-size: 18px;
$nav-font-weight: 400;
$nav-font-style: normal;
$nav-text-transform: initial;
$nav-line-height: 1.4em;
$nav-letter-spacing: 0em;
$nav-color: #332d2c;
$nav-margin: 0px 5px;
$nav-padding: 12px 12px;
$nav-item-border-radius: $border-radius;

$nav-hover-background: transparent;
$nav-hover-color: #332d2c;
$nav-current-color: #332d2c;
$nav-current-background: transparent;

/*
  * 	Navigation Drop Down Items
  */

$nav-dropdown-background: $white;
$nav-dropdown-border-radius: $border-radius;
$nav-dropdown-border: none;
$nav-dropdown-box-shadow: $box-shadow;
$nav-dropdown-container-padding: 10px;
$nav-dropdown-item-padding: 10px 20px;
$nav-dropdown-item-margin: 5px 0px;
$nav-dropdown-item-border-radius: $border-radius-sm;
$nav-dropdown-font-family: $font-family-base;
$nav-dropdown-font-size: 16px;
$nav-dropdown-font-weight: 400;
$nav-dropdown-font-style: normal;
$nav-dropdown-text-transform: initial;
$nav-dropdown-line-height: 1.4em;
$nav-dropdown-letter-spacing: 0em;
$nav-dropdown-color: #332d2c;

$nav-dropdown-hover-background: rgba(0, 0, 0, 0.08);
$nav-dropdown-hover-color: #332d2c;
$nav-dropdown-current-background: $secondary;
$nav-dropdown-current-color: $white;
$nav-dropdown-highlighted-background: rgba(0, 0, 0, 0.08);
$nav-dropdown-highlighted-color: #332d2c;

/*
  * 	Mobile Navigation
  */

$mobile-nav-font-size: 15px;
$mobile-nav-line-height: 1.4em;
$mobile-nav-padding: 10px 15px;
$mobile-current-background: transparent;
$mobile-current-color: $primary;

/*
 * 	Buttons
 */

$btn-padding-y: 15px !default;
$btn-padding-x: 15px !default;
$btn-font-size: 16px !default;
$btn-text-transform: initial !default;
$btn-letter-spacing: 0em !default;
$btn-line-height: 1.4em !default;
$btn-border-width: 0px !default;
$btn-border-radius: 16px !default;
$btn-font-weight: 500 !default;
$btn-box-shadow: none !default;
$btn-focus-width: 1px !default;
$btn-focus-box-shadow: none !default;
$btn-active-box-shadow: none !default;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
	border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
$yiq-contrasted-threshold: 175 !default;

$btn-padding-y-lg: $btn-padding-y * 1.1 !default;
$btn-padding-x-lg: $btn-padding-x * 1.1 !default;
$btn-font-size-lg: $btn-font-size !default;

$btn-padding-y-sm: $btn-padding-y * 0.9 !default;
$btn-padding-x-sm: $btn-padding-x * 0.9 !default;
$btn-font-size-sm: $btn-font-size !default;

/*
 * 	Badges
 */

$badge-padding-y: 0 !default;
$badge-padding-x: 0 !default;
$badge-font-size: 12px !default;
$badge-font-style: normal !default;
$badge-color: $gray-900 !default;
$badge-font-weight: 700 !default;
$badge-text-transform: uppercase !default;
$badge-line-height: 1.4em !default;
$badge-letter-spacing: 0.1em !default;
$badge-border-radius: 0 !default;
$badge-background-color: transparent !default;
$badge-border-width: 0 !default;
$badge-border-color: transparent !default;

$badge-focus-width: 1px !default;

/*
 * 	Default theme - Owl Carousel CSS File
 */

$owl-color-base: theme-color("primary") !default;
$owl-color-white: $white !default;
$owl-color-gray: $gray-500 !default;

//nav

$owl-nav-color: rgba($white, 0.75) !default;
$owl-nav-color-hover: $white !default;
$owl-nav-font-size: 40px !default;
$owl-nav-rounded: 0 !default;
$owl-nav-margin: 0 10px !default;
$owl-nav-padding: 10px !default;
$owl-nav-background: transparent !default;
$owl-nav-background-hover: transparent !default;
$owl-nav-disabled-opacity: 0.5 !default;

//dots

$owl-dot-width: 8px !default;
$owl-dot-height: 8px !default;
$owl-dot-rounded: 100% !default;
$owl-dot-margin: 5px 7px !default;
$owl-dot-background: rgba($white, 0.75) !default;
$owl-dot-background-active: $white !default;
